import { useTranslation } from 'react-i18next';

import { Meta, Page } from '@Components/layout';
import { PromotionBlockList } from '@Components/list';
import {
  Banner,
  Button,
  CategoryPreviewWrapper,
  SearchBlock,
  USPBlock,
  VacancyCategoryCard,
} from '@Components/ui';
import { DEFAULT_REVALIDATE_TIME } from '@Constants/config';
import { COLORS } from '@Constants/enums';
import { HomepageModule, IHomepage } from '@Modules/HomepageModule';
import { IPage, PageModule } from '@Modules/PageModule';
import { IProfile, ProfileModule } from '@Modules/ProfileModule';
import { IVacancy, VacancyModule } from '@Modules/VacancyModule';
import { formatString } from '@Utils/StringUtils';

export interface HomeProps {
  page: IPage;
  homepage: IHomepage;
  profiles: IProfile[];
  vacancies: IVacancy[];
}

export default function Home({ page, homepage, vacancies }: HomeProps) {
  const { t } = useTranslation();

  return (
    <Page page={page} theme={COLORS.WHITE}>
      <Meta
        title={homepage.header_title}
        image={'/images/metaLogo.png'}
        description={homepage.header_description}
        id={homepage._id}
      />
      <Banner
        linkLabel={homepage.header_button_label}
        linkUrl={homepage.header_button_url}
        image={homepage.header_image[0]}
        backgroundColor={COLORS.GREEN}>
        <h1
          dangerouslySetInnerHTML={{
            __html: formatString(homepage.header_title),
          }}
        />

        <p>{homepage.header_description}</p>
      </Banner>
      <section className="section overflow-visible">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <SearchBlock searchTags={page.searchTags} branches={page.branches} />
            </div>
          </div>
        </div>
      </section>

      <section className="section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <USPBlock title={homepage.explanation_title} items={homepage.explanation_blocks} />
            </div>
          </div>
        </div>
      </section>

      <section className="section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-4 text-center">
              <h2 className="mb-md">{homepage.cta_text}</h2>
              <Button href={homepage.cta_button_url}>{homepage.cta_button_label}</Button>
            </div>
          </div>
        </div>
      </section>

      <section className="section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <PromotionBlockList cards={homepage.promotion_blocks} />
            </div>
          </div>
        </div>
      </section>

      <section className="section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <CategoryPreviewWrapper
                title={t('DOMAIN.VACANCY.SLIDER')}
                seeMoreTitle={t('GLOBALS.SEE_ALL', { name: t('GLOBALS.VACANCIES') })}
                seeMoreHref="/vacatures"
                tintColor={COLORS.GREEN}>
                {vacancies.map((vacancy: IVacancy) => (
                  <VacancyCategoryCard key={vacancy.title} vacancy={vacancy} />
                ))}
              </CategoryPreviewWrapper>
            </div>
          </div>
        </div>
      </section>
    </Page>
  );
}

export const getStaticProps = async () => {
  const [page, homepage, vacancies, profiles] = await Promise.all([
    await PageModule.getPage(),
    await HomepageModule.getHomepage(),
    await VacancyModule.getLatetstVacancies(3),
    await ProfileModule.getProfiles(),
  ]);

  return {
    props: {
      page,
      homepage,
      vacancies,
      profiles,
    } as HomeProps,
    revalidate: DEFAULT_REVALIDATE_TIME,
  };
};
