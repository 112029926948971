import cn from 'classnames';

import { PromotionBlock } from '@Components/ui';
import { COLORS } from '@Constants/enums';

import styles from './PromotionBlockList.module.scss';
import { PromotionBlockListProps } from './PromotionBlockList.props';

export const PromotionBlockList = ({ cards }: PromotionBlockListProps) => {
  return (
    <div className={styles.PromotionBlockList}>
      {cards.map((card, index) => {
        return (
          <div key={card.title} className={cn(styles.PromotionBlockList__Item)}>
            <PromotionBlock
              title={card.title}
              thumbnailUri={card.image[0].cdn_files[0].full}
              color={card.color ? card.color : index % 2 === 0 ? COLORS.PINK : COLORS.BLUE}
              bodyTitle={card.subtitle}
              linkLabel={card.link_label}
              linkUrl={card.link_url}>
              <p dangerouslySetInnerHTML={{ __html: card.body }} />
            </PromotionBlock>
          </div>
        );
      })}
    </div>
  );
};
