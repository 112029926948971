import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { AuctionItem } from '@Components/ui';

import styles from './AuctionList.module.scss';
import { AuctionListProps } from './AuctionList.props';

export const AuctionList = ({ items, className }: AuctionListProps) => {
  const { t } = useTranslation();

  return (
    <div className={cn(styles.AuctionList, className)}>
      <h2 className={cn('h1', styles.Title)}>{t('COMPONENTS.AUCTION_LIST.TITLE')}</h2>
      {!!items && (
        <ul>
          {items.map((item) => (
            <li key={item._id} className={styles.AuctionItem}>
              <AuctionItem {...item} />
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
